import { Center, Grid, GridItem, Text } from "@chakra-ui/react";
import React from "react";

interface Entry {
  type: string;
  value: string;
}

interface DataObject {
  [key: string]: Entry[];
}

interface ExploreCountryProps {
  data: DataObject;
  color: string;
}

const InnoExploreGrid: React.FC<ExploreCountryProps> = ({ data, color }) => {
  const dynamicKey = Object.keys(data)[0] as keyof DataObject;
  const entries = data[dynamicKey];
  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={4}>
      {entries?.map((entry: Entry) => (
        <GridItem
          key={entry.type}
          h="35px"
          bg={color}
          p="8px"
          borderRadius="md"
        >
          <Center>
            <Text fontWeight={600} fontSize="11px">
              {entry.value}
            </Text>
          </Center>
        </GridItem>
      ))}
    </Grid>
  );
};

export default InnoExploreGrid;
