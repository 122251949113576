import { Text } from "@chakra-ui/react";
import { ColumnDef, Row } from "@tanstack/react-table";
import { ScenarioType } from "./types";
import {
  CheckboxHeader,
  formatDate,
  IncMaCoHeader,
  preCheckBox,
  TableCell,
} from "./PreLaunch/PreLaunchHelper";
import { useMemo } from "react";
export const clientId =
  import.meta.env.VITE_CLIENT_ID ?? "5d7c1d57-b6fd-4f46-bd7c-c9589ebdfef1";
export const redirectUri =
  import.meta.env.VITE_REDIRECT_URI ?? "https://innovatex.ai-stg.ab-inbev.com";
export const fixed =
  import.meta.env.VITE_API_URL ?? "https://dev.innox.ab-inbev.com/api/";

type CellComponent = (props: { row: Row<ScenarioType> }) => JSX.Element;

export const categoryDistributionRSA = { BEER: 6.11, "BEYOND BEER": 6.15 };
export const categoryDistributionBR = {
  BEER: 4.9,
  "BEYOND BEER": 22.8,
  NAB: 21.8,
  "NON ALC BEER": 5.9,
};


export const updatedExplorePer = [
  {
    country: "SAF",
    abi: [
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.15%",
              },
              {
                type: "NRB",
                value: "8.77%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "70.02%",
              },
              {
                type: "CAN",
                value: "21.05%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.06%",
              },
              {
                type: "NRB",
                value: "1.86%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "91.37%",
              },
              {
                type: "CAN",
                value: "6.72%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "79.60%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "8.14%",
              },
              {
                type: "NRB",
                value: "12.25%",
              },
            ],
          },
        ],
      },
      {
        type: "BUDWEISER",
        segment: "P / SP",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "100.00%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CARLING BLACK LABEL",
        segment: "CORE",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.05%",
              },
              {
                type: "NRB",
                value: "1.56%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "92.51%",
              },
              {
                type: "CAN",
                value: "5.87%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE DOUBLE MALT",
        segment: "CORE+",
        "Brand Category": "Expansion",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "6.85%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "71.46%",
              },
              {
                type: "CAN",
                value: "21.68%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE LAGER",
        segment: "CORE",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.11%",
              },
              {
                type: "NRB",
                value: "2.49%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "90.58%",
              },
              {
                type: "CAN",
                value: "6.82%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE LITE",
        segment: "CORE+",
        "Brand Category": "Sustain",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.28%",
              },
              {
                type: "NRB",
                value: "13.81%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "56.63%",
              },
              {
                type: "CAN",
                value: "29.29%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE MILK STOUT",
        segment: "CORE+",
        "Brand Category": "Sustain",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "1.82%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "89.19%",
              },
              {
                type: "CAN",
                value: "8.99%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CORONA",
        segment: "P / SP",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "94.02%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "5.98%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },

      {
        type: "HANSA PILSNER",
        segment: "CORE",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "4.24%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "81.89%",
              },
              {
                type: "CAN",
                value: "13.87%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "LION LAGER",
        segment: "CORE",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "85.37%",
              },
              {
                type: "CAN",
                value: "14.63%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "STELLA ARTOIS",
        segment: "P / SP",
        "Brand Category": "Expansion",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "36.18%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "14.71%",
              },
              {
                type: "NRB",
                value: "49.11%",
              },
            ],
          },
        ],
      },
    ],
    "heineken beverages": [
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.10%",
              },
              {
                type: "NRB",
                value: "14.42%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "63.54%",
              },
              {
                type: "CAN",
                value: "20.83%",
              },
              {
                type: "NRB",
                value: "1.11%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "45.65%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "54.24%",
              },
              {
                type: "CAN",
                value: "0.11%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.11%",
              },
              {
                type: "NRB",
                value: "20.16%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "40.29%",
              },
              {
                type: "CAN",
                value: "15.97%",
              },
              {
                type: "NRB",
                value: "23.47%",
              },
            ],
          },
        ],
      },
      {
        type: "AMSTEL",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.19%",
              },
              {
                type: "NRB",
                value: "4.48%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "81.71%",
              },
              {
                type: "CAN",
                value: "11.26%",
              },
              {
                type: "NRB",
                value: "2.36%",
              },
            ],
          },
        ],
      },

      {
        type: "HEINEKEN",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.12%",
              },
              {
                type: "NRB",
                value: "17.33%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "41.73%",
              },
              {
                type: "CAN",
                value: "16.53%",
              },
              {
                type: "NRB",
                value: "24.30%",
              },
            ],
          },
        ],
      },
      {
        type: "SOL",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "99.81%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0.19%",
              },
            ],
          },
        ],
      },
      {
        type: "SOWETO GOLD",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "100.00%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "TAFEL",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "97.25%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "2.52%",
              },
              {
                type: "CAN",
                value: "0.23%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "WINDHOEK",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.03%",
              },
              {
                type: "NRB",
                value: "23.25%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "47.40%",
              },
              {
                type: "CAN",
                value: "29.32%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
    ],
    "total market": [
      {
        type: "CORE",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.06%",
              },
              {
                type: "NRB",
                value: "1.92%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "91.31%",
              },
              {
                type: "CAN",
                value: "6.71%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CORE+",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.14%",
              },
              {
                type: "NRB",
                value: "10.32%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "68.25%",
              },
              {
                type: "CAN",
                value: "20.99%",
              },
              {
                type: "NRB",
                value: "0.30%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.08%",
              },
              {
                type: "NRB",
                value: "8.90%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "77.74%",
              },
              {
                type: "CAN",
                value: "10.88%",
              },
              {
                type: "NRB",
                value: "2.40%",
              },
            ],
          },
        ],
      },
      {
        type: "P / SP",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.07%",
              },
              {
                type: "NRB",
                value: "42.97%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "24.83%",
              },
              {
                type: "CAN",
                value: "12.96%",
              },
              {
                type: "NRB",
                value: "19.17%",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    country: "India",
    abi: [
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.15%",
              },
              {
                type: "NRB",
                value: "8.77%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "70.02%",
              },
              {
                type: "CAN",
                value: "21.05%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.06%",
              },
              {
                type: "NRB",
                value: "1.86%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "91.37%",
              },
              {
                type: "CAN",
                value: "6.72%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "79.60%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "8.14%",
              },
              {
                type: "NRB",
                value: "12.25%",
              },
            ],
          },
        ],
      },
      {
        type: "BUDWEISER",
        segment: "P / SP",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "100.00%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CARLING BLACK LABEL",
        segment: "CORE",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.05%",
              },
              {
                type: "NRB",
                value: "1.56%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "92.51%",
              },
              {
                type: "CAN",
                value: "5.87%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE DOUBLE MALT",
        segment: "CORE+",
        "Brand Category": "Expansion",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "6.85%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "71.46%",
              },
              {
                type: "CAN",
                value: "21.68%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE LAGER",
        segment: "CORE",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.11%",
              },
              {
                type: "NRB",
                value: "2.49%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "90.58%",
              },
              {
                type: "CAN",
                value: "6.82%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE LITE",
        segment: "CORE+",
        "Brand Category": "Sustain",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.28%",
              },
              {
                type: "NRB",
                value: "13.81%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "56.63%",
              },
              {
                type: "CAN",
                value: "29.29%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE MILK STOUT",
        segment: "CORE+",
        "Brand Category": "Sustain",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "1.82%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "89.19%",
              },
              {
                type: "CAN",
                value: "8.99%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CORONA",
        segment: "P / SP",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "94.02%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "5.98%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },

      {
        type: "HANSA PILSNER",
        segment: "CORE",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "4.24%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "81.89%",
              },
              {
                type: "CAN",
                value: "13.87%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "LION LAGER",
        segment: "CORE",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "85.37%",
              },
              {
                type: "CAN",
                value: "14.63%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "STELLA ARTOIS",
        segment: "P / SP",
        "Brand Category": "Expansion",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "36.18%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "14.71%",
              },
              {
                type: "NRB",
                value: "49.11%",
              },
            ],
          },
        ],
      },
    ],
    "heineken beverages": [
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.10%",
              },
              {
                type: "NRB",
                value: "14.42%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "63.54%",
              },
              {
                type: "CAN",
                value: "20.83%",
              },
              {
                type: "NRB",
                value: "1.11%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "45.65%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "54.24%",
              },
              {
                type: "CAN",
                value: "0.11%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.11%",
              },
              {
                type: "NRB",
                value: "20.16%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "40.29%",
              },
              {
                type: "CAN",
                value: "15.97%",
              },
              {
                type: "NRB",
                value: "23.47%",
              },
            ],
          },
        ],
      },
      {
        type: "AMSTEL",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.19%",
              },
              {
                type: "NRB",
                value: "4.48%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "81.71%",
              },
              {
                type: "CAN",
                value: "11.26%",
              },
              {
                type: "NRB",
                value: "2.36%",
              },
            ],
          },
        ],
      },

      {
        type: "HEINEKEN",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.12%",
              },
              {
                type: "NRB",
                value: "17.33%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "41.73%",
              },
              {
                type: "CAN",
                value: "16.53%",
              },
              {
                type: "NRB",
                value: "24.30%",
              },
            ],
          },
        ],
      },
      {
        type: "SOL",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "99.81%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0.19%",
              },
            ],
          },
        ],
      },
      {
        type: "SOWETO GOLD",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "100.00%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "TAFEL",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "97.25%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "2.52%",
              },
              {
                type: "CAN",
                value: "0.23%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "WINDHOEK",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.03%",
              },
              {
                type: "NRB",
                value: "23.25%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "47.40%",
              },
              {
                type: "CAN",
                value: "29.32%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
    ],
    "total market": [
      {
        type: "CORE",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.06%",
              },
              {
                type: "NRB",
                value: "1.92%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "91.31%",
              },
              {
                type: "CAN",
                value: "6.71%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CORE+",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.14%",
              },
              {
                type: "NRB",
                value: "10.32%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "68.25%",
              },
              {
                type: "CAN",
                value: "20.99%",
              },
              {
                type: "NRB",
                value: "0.30%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.08%",
              },
              {
                type: "NRB",
                value: "8.90%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "77.74%",
              },
              {
                type: "CAN",
                value: "10.88%",
              },
              {
                type: "NRB",
                value: "2.40%",
              },
            ],
          },
        ],
      },
      {
        type: "P / SP",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.07%",
              },
              {
                type: "NRB",
                value: "42.97%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "24.83%",
              },
              {
                type: "CAN",
                value: "12.96%",
              },
              {
                type: "NRB",
                value: "19.17%",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    country: "Canada",
    abi: [
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.15%",
              },
              {
                type: "NRB",
                value: "8.77%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "70.02%",
              },
              {
                type: "CAN",
                value: "21.05%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.06%",
              },
              {
                type: "NRB",
                value: "1.86%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "91.37%",
              },
              {
                type: "CAN",
                value: "6.72%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "79.60%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "8.14%",
              },
              {
                type: "NRB",
                value: "12.25%",
              },
            ],
          },
        ],
      },
      {
        type: "BUDWEISER",
        segment: "P / SP",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "100.00%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CARLING BLACK LABEL",
        segment: "CORE",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.05%",
              },
              {
                type: "NRB",
                value: "1.56%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "92.51%",
              },
              {
                type: "CAN",
                value: "5.87%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE DOUBLE MALT",
        segment: "CORE+",
        "Brand Category": "Expansion",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "6.85%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "71.46%",
              },
              {
                type: "CAN",
                value: "21.68%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE LAGER",
        segment: "CORE",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.11%",
              },
              {
                type: "NRB",
                value: "2.49%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "90.58%",
              },
              {
                type: "CAN",
                value: "6.82%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE LITE",
        segment: "CORE+",
        "Brand Category": "Sustain",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.28%",
              },
              {
                type: "NRB",
                value: "13.81%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "56.63%",
              },
              {
                type: "CAN",
                value: "29.29%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE MILK STOUT",
        segment: "CORE+",
        "Brand Category": "Sustain",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "1.82%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "89.19%",
              },
              {
                type: "CAN",
                value: "8.99%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CORONA",
        segment: "P / SP",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "94.02%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "5.98%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },

      {
        type: "HANSA PILSNER",
        segment: "CORE",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "4.24%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "81.89%",
              },
              {
                type: "CAN",
                value: "13.87%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "LION LAGER",
        segment: "CORE",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "85.37%",
              },
              {
                type: "CAN",
                value: "14.63%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "STELLA ARTOIS",
        segment: "P / SP",
        "Brand Category": "Expansion",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "36.18%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "14.71%",
              },
              {
                type: "NRB",
                value: "49.11%",
              },
            ],
          },
        ],
      },
    ],
    "heineken beverages": [
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.10%",
              },
              {
                type: "NRB",
                value: "14.42%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "63.54%",
              },
              {
                type: "CAN",
                value: "20.83%",
              },
              {
                type: "NRB",
                value: "1.11%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "45.65%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "54.24%",
              },
              {
                type: "CAN",
                value: "0.11%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.11%",
              },
              {
                type: "NRB",
                value: "20.16%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "40.29%",
              },
              {
                type: "CAN",
                value: "15.97%",
              },
              {
                type: "NRB",
                value: "23.47%",
              },
            ],
          },
        ],
      },
      {
        type: "AMSTEL",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.19%",
              },
              {
                type: "NRB",
                value: "4.48%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "81.71%",
              },
              {
                type: "CAN",
                value: "11.26%",
              },
              {
                type: "NRB",
                value: "2.36%",
              },
            ],
          },
        ],
      },

      {
        type: "HEINEKEN",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.12%",
              },
              {
                type: "NRB",
                value: "17.33%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "41.73%",
              },
              {
                type: "CAN",
                value: "16.53%",
              },
              {
                type: "NRB",
                value: "24.30%",
              },
            ],
          },
        ],
      },
      {
        type: "SOL",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "99.81%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0.19%",
              },
            ],
          },
        ],
      },
      {
        type: "SOWETO GOLD",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "100.00%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "TAFEL",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "97.25%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "2.52%",
              },
              {
                type: "CAN",
                value: "0.23%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "WINDHOEK",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.03%",
              },
              {
                type: "NRB",
                value: "23.25%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "47.40%",
              },
              {
                type: "CAN",
                value: "29.32%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
    ],
    "total market": [
      {
        type: "CORE",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.06%",
              },
              {
                type: "NRB",
                value: "1.92%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "91.31%",
              },
              {
                type: "CAN",
                value: "6.71%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CORE+",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.14%",
              },
              {
                type: "NRB",
                value: "10.32%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "68.25%",
              },
              {
                type: "CAN",
                value: "20.99%",
              },
              {
                type: "NRB",
                value: "0.30%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.08%",
              },
              {
                type: "NRB",
                value: "8.90%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "77.74%",
              },
              {
                type: "CAN",
                value: "10.88%",
              },
              {
                type: "NRB",
                value: "2.40%",
              },
            ],
          },
        ],
      },
      {
        type: "P / SP",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.07%",
              },
              {
                type: "NRB",
                value: "42.97%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "24.83%",
              },
              {
                type: "CAN",
                value: "12.96%",
              },
              {
                type: "NRB",
                value: "19.17%",
              },
            ],
          },
        ],
      },
    ],
  },
];

export const updatedExplorePerWithPercentage = updatedExplorePer;

export const updatedExploreAbs = [
  {
    country: "SAF",
    abi: [
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "6.11",
              },
              {
                type: "NRB",
                value: "318.48",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "2,566.18",
              },
              {
                type: "CAN",
                value: "784.89",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "6.42",
              },
              {
                type: "NRB",
                value: "371.13",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "2,962.36",
              },
              {
                type: "CAN",
                value: "890.57",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0.02",
              },
              {
                type: "NRB",
                value: "903.13",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "92.39",
              },
              {
                type: "NRB",
                value: "139.00",
              },
            ],
          },
        ],
      },
      {
        type: "BUDWEISER",
        segment: "P / SP",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "3.49",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CARLING BLACK LABEL",
        segment: "CORE",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "5.33",
              },
              {
                type: "NRB",
                value: "171.73",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "10,156.50",
              },
              {
                type: "CAN",
                value: "644.67",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE DOUBLE MALT",
        segment: "CORE+",
        "Brand Category": "Expansion",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "20.18",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "210.31",
              },
              {
                type: "CAN",
                value: "63.80",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE LAGER",
        segment: "CORE",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "3.75",
              },
              {
                type: "NRB",
                value: "84.53",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "3,078.30",
              },
              {
                type: "CAN",
                value: "231.76",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE LITE",
        segment: "CORE+",
        "Brand Category": "Sustain",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "6.42",
              },
              {
                type: "NRB",
                value: "321.69",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1,319.49",
              },
              {
                type: "CAN",
                value: "682.40",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE MILK STOUT",
        segment: "CORE+",
        "Brand Category": "Sustain",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "29.27",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1,432.56",
              },
              {
                type: "CAN",
                value: "144.36",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CORONA",
        segment: "P / SP",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "797.24",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "50.75",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },

      {
        type: "HANSA PILSNER",
        segment: "CORE",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "33.38",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "644.56",
              },
              {
                type: "CAN",
                value: "109.19",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "LION LAGER",
        segment: "CORE",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "356.16",
              },
              {
                type: "CAN",
                value: "61.04",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "STELLA ARTOIS",
        segment: "P / SP",
        "Brand Category": "Expansion",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "102.39",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "41.64",
              },
              {
                type: "NRB",
                value: "139.00",
              },
            ],
          },
        ],
      },
    ],
    "heineken beverages": [
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "1.66",
              },
              {
                type: "NRB",
                value: "229.47",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1,010.78",
              },
              {
                type: "CAN",
                value: "331.34",
              },
              {
                type: "NRB",
                value: "17.68",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "10.37",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "12.30",
              },
              {
                type: "CAN",
                value: "0.04",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "2.06",
              },
              {
                type: "NRB",
                value: "367.45",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "734.29",
              },
              {
                type: "CAN",
                value: "290.97",
              },
              {
                type: "NRB",
                value: "427.74",
              },
            ],
          },
        ],
      },
      {
        type: "AMSTEL",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "1.44",
              },
              {
                type: "NRB",
                value: "33.47",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "611.23",
              },
              {
                type: "CAN",
                value: "84.25",
              },
              {
                type: "NRB",
                value: "17.68",
              },
            ],
          },
        ],
      },

      {
        type: "HEINEKEN",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "2.06",
              },
              {
                type: "NRB",
                value: "304.90",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "734.29",
              },
              {
                type: "CAN",
                value: "290.92",
              },
              {
                type: "NRB",
                value: "427.61",
              },
            ],
          },
        ],
      },
      {
        type: "SOL",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "62.55",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0.13",
              },
            ],
          },
        ],
      },
      {
        type: "SOWETO GOLD",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "12.04",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "TAFEL",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "10.37",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0.27",
              },
              {
                type: "CAN",
                value: "0.04",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "WINDHOEK",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0.22",
              },
              {
                type: "NRB",
                value: "196.00",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "399.54",
              },
              {
                type: "CAN",
                value: "247.09",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
    ],
    "total market": [
      {
        type: "CORE",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "9.08",
              },
              {
                type: "NRB",
                value: "300.00",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "14,247.81",
              },
              {
                type: "CAN",
                value: "1,046.70",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CORE+",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "8.08",
              },
              {
                type: "NRB",
                value: "600.60",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "3,973.14",
              },
              {
                type: "CAN",
                value: "1,221.91",
              },
              {
                type: "NRB",
                value: "17.68",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "19.22",
              },
              {
                type: "NRB",
                value: "2,171.18",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "18,955.24",
              },
              {
                type: "CAN",
                value: "2,651.92",
              },
              {
                type: "NRB",
                value: "584.42",
              },
            ],
          },
        ],
      },
      {
        type: "P / SP",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "2.06",
              },
              {
                type: "NRB",
                value: "1,270.57",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "734.29",
              },
              {
                type: "CAN",
                value: "383.31",
              },
              {
                type: "NRB",
                value: "566.74",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    country: "India",
    abi: [
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "6.11",
              },
              {
                type: "NRB",
                value: "318.48",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "2,566.18",
              },
              {
                type: "CAN",
                value: "784.89",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "6.42",
              },
              {
                type: "NRB",
                value: "371.13",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "2,962.36",
              },
              {
                type: "CAN",
                value: "890.57",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0.02",
              },
              {
                type: "NRB",
                value: "903.13",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "92.39",
              },
              {
                type: "NRB",
                value: "139.00",
              },
            ],
          },
        ],
      },
      {
        type: "BUDWEISER",
        segment: "P / SP",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "3.49",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CARLING BLACK LABEL",
        segment: "CORE",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "5.33",
              },
              {
                type: "NRB",
                value: "171.73",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "10,156.50",
              },
              {
                type: "CAN",
                value: "644.67",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE DOUBLE MALT",
        segment: "CORE+",
        "Brand Category": "Expansion",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "20.18",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "210.31",
              },
              {
                type: "CAN",
                value: "63.80",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE LAGER",
        segment: "CORE",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "3.75",
              },
              {
                type: "NRB",
                value: "84.53",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "3,078.30",
              },
              {
                type: "CAN",
                value: "231.76",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE LITE",
        segment: "CORE+",
        "Brand Category": "Sustain",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "6.42",
              },
              {
                type: "NRB",
                value: "321.69",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1,319.49",
              },
              {
                type: "CAN",
                value: "682.40",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE MILK STOUT",
        segment: "CORE+",
        "Brand Category": "Sustain",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "29.27",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1,432.56",
              },
              {
                type: "CAN",
                value: "144.36",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CORONA",
        segment: "P / SP",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "797.24",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "50.75",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },

      {
        type: "HANSA PILSNER",
        segment: "CORE",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "33.38",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "644.56",
              },
              {
                type: "CAN",
                value: "109.19",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "LION LAGER",
        segment: "CORE",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "356.16",
              },
              {
                type: "CAN",
                value: "61.04",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "STELLA ARTOIS",
        segment: "P / SP",
        "Brand Category": "Expansion",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "102.39",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "41.64",
              },
              {
                type: "NRB",
                value: "139.00",
              },
            ],
          },
        ],
      },
    ],
    "heineken beverages": [
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "1.66",
              },
              {
                type: "NRB",
                value: "229.47",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1,010.78",
              },
              {
                type: "CAN",
                value: "331.34",
              },
              {
                type: "NRB",
                value: "17.68",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "10.37",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "12.30",
              },
              {
                type: "CAN",
                value: "0.04",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "2.06",
              },
              {
                type: "NRB",
                value: "367.45",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "734.29",
              },
              {
                type: "CAN",
                value: "290.97",
              },
              {
                type: "NRB",
                value: "427.74",
              },
            ],
          },
        ],
      },
      {
        type: "AMSTEL",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "1.44",
              },
              {
                type: "NRB",
                value: "33.47",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "611.23",
              },
              {
                type: "CAN",
                value: "84.25",
              },
              {
                type: "NRB",
                value: "17.68",
              },
            ],
          },
        ],
      },

      {
        type: "HEINEKEN",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "2.06",
              },
              {
                type: "NRB",
                value: "304.90",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "734.29",
              },
              {
                type: "CAN",
                value: "290.92",
              },
              {
                type: "NRB",
                value: "427.61",
              },
            ],
          },
        ],
      },
      {
        type: "SOL",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "62.55",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0.13",
              },
            ],
          },
        ],
      },
      {
        type: "SOWETO GOLD",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "12.04",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "TAFEL",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "10.37",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0.27",
              },
              {
                type: "CAN",
                value: "0.04",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "WINDHOEK",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0.22",
              },
              {
                type: "NRB",
                value: "196.00",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "399.54",
              },
              {
                type: "CAN",
                value: "247.09",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
    ],
    "total market": [
      {
        type: "CORE",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "9.08",
              },
              {
                type: "NRB",
                value: "300.00",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "14,247.81",
              },
              {
                type: "CAN",
                value: "1,046.70",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CORE+",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "8.08",
              },
              {
                type: "NRB",
                value: "600.60",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "3,973.14",
              },
              {
                type: "CAN",
                value: "1,221.91",
              },
              {
                type: "NRB",
                value: "17.68",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "19.22",
              },
              {
                type: "NRB",
                value: "2,171.18",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "18,955.24",
              },
              {
                type: "CAN",
                value: "2,651.92",
              },
              {
                type: "NRB",
                value: "584.42",
              },
            ],
          },
        ],
      },
      {
        type: "P / SP",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "2.06",
              },
              {
                type: "NRB",
                value: "1,270.57",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "734.29",
              },
              {
                type: "CAN",
                value: "383.31",
              },
              {
                type: "NRB",
                value: "566.74",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    country: "Canada",
    abi: [
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "6.11",
              },
              {
                type: "NRB",
                value: "318.48",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "2,566.18",
              },
              {
                type: "CAN",
                value: "784.89",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "6.42",
              },
              {
                type: "NRB",
                value: "371.13",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "2,962.36",
              },
              {
                type: "CAN",
                value: "890.57",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0.02",
              },
              {
                type: "NRB",
                value: "903.13",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "92.39",
              },
              {
                type: "NRB",
                value: "139.00",
              },
            ],
          },
        ],
      },
      {
        type: "BUDWEISER",
        segment: "P / SP",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "3.49",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CARLING BLACK LABEL",
        segment: "CORE",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "5.33",
              },
              {
                type: "NRB",
                value: "171.73",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "10,156.50",
              },
              {
                type: "CAN",
                value: "644.67",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE DOUBLE MALT",
        segment: "CORE+",
        "Brand Category": "Expansion",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "20.18",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "210.31",
              },
              {
                type: "CAN",
                value: "63.80",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE LAGER",
        segment: "CORE",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "3.75",
              },
              {
                type: "NRB",
                value: "84.53",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "3,078.30",
              },
              {
                type: "CAN",
                value: "231.76",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE LITE",
        segment: "CORE+",
        "Brand Category": "Sustain",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "6.42",
              },
              {
                type: "NRB",
                value: "321.69",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1,319.49",
              },
              {
                type: "CAN",
                value: "682.40",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE MILK STOUT",
        segment: "CORE+",
        "Brand Category": "Sustain",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "29.27",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1,432.56",
              },
              {
                type: "CAN",
                value: "144.36",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CORONA",
        segment: "P / SP",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "797.24",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "50.75",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },

      {
        type: "HANSA PILSNER",
        segment: "CORE",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "33.38",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "644.56",
              },
              {
                type: "CAN",
                value: "109.19",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "LION LAGER",
        segment: "CORE",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "356.16",
              },
              {
                type: "CAN",
                value: "61.04",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "STELLA ARTOIS",
        segment: "P / SP",
        "Brand Category": "Expansion",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "102.39",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "41.64",
              },
              {
                type: "NRB",
                value: "139.00",
              },
            ],
          },
        ],
      },
    ],
    "heineken beverages": [
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "1.66",
              },
              {
                type: "NRB",
                value: "229.47",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1,010.78",
              },
              {
                type: "CAN",
                value: "331.34",
              },
              {
                type: "NRB",
                value: "17.68",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "10.37",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "12.30",
              },
              {
                type: "CAN",
                value: "0.04",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "2.06",
              },
              {
                type: "NRB",
                value: "367.45",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "734.29",
              },
              {
                type: "CAN",
                value: "290.97",
              },
              {
                type: "NRB",
                value: "427.74",
              },
            ],
          },
        ],
      },
      {
        type: "AMSTEL",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "1.44",
              },
              {
                type: "NRB",
                value: "33.47",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "611.23",
              },
              {
                type: "CAN",
                value: "84.25",
              },
              {
                type: "NRB",
                value: "17.68",
              },
            ],
          },
        ],
      },

      {
        type: "HEINEKEN",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "2.06",
              },
              {
                type: "NRB",
                value: "304.90",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "734.29",
              },
              {
                type: "CAN",
                value: "290.92",
              },
              {
                type: "NRB",
                value: "427.61",
              },
            ],
          },
        ],
      },
      {
        type: "SOL",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "62.55",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0.13",
              },
            ],
          },
        ],
      },
      {
        type: "SOWETO GOLD",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "12.04",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "TAFEL",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "10.37",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0.27",
              },
              {
                type: "CAN",
                value: "0.04",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "WINDHOEK",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0.22",
              },
              {
                type: "NRB",
                value: "196.00",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "399.54",
              },
              {
                type: "CAN",
                value: "247.09",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
    ],
    "total market": [
      {
        type: "CORE",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "9.08",
              },
              {
                type: "NRB",
                value: "300.00",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "14,247.81",
              },
              {
                type: "CAN",
                value: "1,046.70",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CORE+",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "8.08",
              },
              {
                type: "NRB",
                value: "600.60",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "3,973.14",
              },
              {
                type: "CAN",
                value: "1,221.91",
              },
              {
                type: "NRB",
                value: "17.68",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "19.22",
              },
              {
                type: "NRB",
                value: "2,171.18",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "18,955.24",
              },
              {
                type: "CAN",
                value: "2,651.92",
              },
              {
                type: "NRB",
                value: "584.42",
              },
            ],
          },
        ],
      },
      {
        type: "P / SP",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "2.06",
              },
              {
                type: "NRB",
                value: "1,270.57",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "734.29",
              },
              {
                type: "CAN",
                value: "383.31",
              },
              {
                type: "NRB",
                value: "566.74",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    country: "Japan",
    abi: [
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "6.11",
              },
              {
                type: "NRB",
                value: "318.48",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "2,566.18",
              },
              {
                type: "CAN",
                value: "784.89",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "6.42",
              },
              {
                type: "NRB",
                value: "371.13",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "2,962.36",
              },
              {
                type: "CAN",
                value: "890.57",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0.02",
              },
              {
                type: "NRB",
                value: "903.13",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "92.39",
              },
              {
                type: "NRB",
                value: "139.00",
              },
            ],
          },
        ],
      },
      {
        type: "BUDWEISER",
        segment: "P / SP",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "3.49",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CARLING BLACK LABEL",
        segment: "CORE",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "5.33",
              },
              {
                type: "NRB",
                value: "171.73",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "10,156.50",
              },
              {
                type: "CAN",
                value: "644.67",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE DOUBLE MALT",
        segment: "CORE+",
        "Brand Category": "Expansion",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "20.18",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "210.31",
              },
              {
                type: "CAN",
                value: "63.80",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE LAGER",
        segment: "CORE",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "3.75",
              },
              {
                type: "NRB",
                value: "84.53",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "3,078.30",
              },
              {
                type: "CAN",
                value: "231.76",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE LITE",
        segment: "CORE+",
        "Brand Category": "Sustain",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "6.42",
              },
              {
                type: "NRB",
                value: "321.69",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1,319.49",
              },
              {
                type: "CAN",
                value: "682.40",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE MILK STOUT",
        segment: "CORE+",
        "Brand Category": "Sustain",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "29.27",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1,432.56",
              },
              {
                type: "CAN",
                value: "144.36",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CORONA",
        segment: "P / SP",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "797.24",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "50.75",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },

      {
        type: "HANSA PILSNER",
        segment: "CORE",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "33.38",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "644.56",
              },
              {
                type: "CAN",
                value: "109.19",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "LION LAGER",
        segment: "CORE",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "356.16",
              },
              {
                type: "CAN",
                value: "61.04",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "STELLA ARTOIS",
        segment: "P / SP",
        "Brand Category": "Expansion",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "102.39",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "41.64",
              },
              {
                type: "NRB",
                value: "139.00",
              },
            ],
          },
        ],
      },
    ],
    "heineken beverages": [
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "1.66",
              },
              {
                type: "NRB",
                value: "229.47",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1,010.78",
              },
              {
                type: "CAN",
                value: "331.34",
              },
              {
                type: "NRB",
                value: "17.68",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "10.37",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "12.30",
              },
              {
                type: "CAN",
                value: "0.04",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "2.06",
              },
              {
                type: "NRB",
                value: "367.45",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "734.29",
              },
              {
                type: "CAN",
                value: "290.97",
              },
              {
                type: "NRB",
                value: "427.74",
              },
            ],
          },
        ],
      },
      {
        type: "AMSTEL",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "1.44",
              },
              {
                type: "NRB",
                value: "33.47",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "611.23",
              },
              {
                type: "CAN",
                value: "84.25",
              },
              {
                type: "NRB",
                value: "17.68",
              },
            ],
          },
        ],
      },

      {
        type: "HEINEKEN",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "2.06",
              },
              {
                type: "NRB",
                value: "304.90",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "734.29",
              },
              {
                type: "CAN",
                value: "290.92",
              },
              {
                type: "NRB",
                value: "427.61",
              },
            ],
          },
        ],
      },
      {
        type: "SOL",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "62.55",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0.13",
              },
            ],
          },
        ],
      },
      {
        type: "SOWETO GOLD",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "12.04",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "TAFEL",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "10.37",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0.27",
              },
              {
                type: "CAN",
                value: "0.04",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "WINDHOEK",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0.22",
              },
              {
                type: "NRB",
                value: "196.00",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "399.54",
              },
              {
                type: "CAN",
                value: "247.09",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
    ],
    "total market": [
      {
        type: "CORE",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "9.08",
              },
              {
                type: "NRB",
                value: "300.00",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "14,247.81",
              },
              {
                type: "CAN",
                value: "1,046.70",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CORE+",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "8.08",
              },
              {
                type: "NRB",
                value: "600.60",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "3,973.14",
              },
              {
                type: "CAN",
                value: "1,221.91",
              },
              {
                type: "NRB",
                value: "17.68",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "19.22",
              },
              {
                type: "NRB",
                value: "2,171.18",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "18,955.24",
              },
              {
                type: "CAN",
                value: "2,651.92",
              },
              {
                type: "NRB",
                value: "584.42",
              },
            ],
          },
        ],
      },
      {
        type: "P / SP",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "2.06",
              },
              {
                type: "NRB",
                value: "1,270.57",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "734.29",
              },
              {
                type: "CAN",
                value: "383.31",
              },
              {
                type: "NRB",
                value: "566.74",
              },
            ],
          },
        ],
      },
    ],
  },
];

export const pptData = [
  {
    Zone: "Africa",
    Country: "South Africa",
    Financials_source: "UMAMI",
    Refresh_year: 2024,
    Refresh_month: "August",
  },
  {
    Zone: "SAZ",
    Country: "Brazil",
    Financials_source: "CUBO",
    Refresh_year: 2024,
    Refresh_month: "August",
  },
];

export const filterByCountry = (country: string) =>
  pptData.filter((item) => item.Country === country)[0];

export const chakraStyles = (
  customWidth: string,
  customFont: string,
  customAlign: string
) => ({
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    width: customWidth,
    fontSize: customFont,
    fontWeight: "600",
    textAlign: customAlign,
    color: "black",
    bg: "white",
    padding: "0 16px 0",
    backgroundColor: "white !important",
    // border: "none",
    borderColor: state.isFocused
      ? "primaryBlack !important"
      : "border !important",
    borderRadius: "8px",
    boxShadow: "none !important",
  }),
  placeholder: (baseStyles: any) => ({
    ...baseStyles,
    color: "#1E1E1ECC",
  }),
  valueContainer: (baseStyles: any) => ({
    ...baseStyles,
    px: "0",
  }),
  multiValue: (baseStyles: any) => ({
    ...baseStyles,
    bg: "border",
    fontSize: "12px",
  }),
  dropdownIndicator: (baseStyles: any) => ({
    ...baseStyles,
    px: "200px",
    bg: "green",
    cursor: "pointer",
  }),
  option: (baseStyles: any, state: any) => ({
    ...baseStyles,
    color: "textBlack !important",
    fontSize: "12px",
    background: state.isFocused ? "border !important" : "none",
  }),
});
export const filterInfo: { [key: string]: string } = {
  "Price per HL": "Expected NR per HL",
  "MACO per HL": "Unplugged MACO/HL",
  Distribution: "% of POC where the product will be available",
};

export const desiredOrder = [
  "P_Brand",
  "P_Subbrand",
  "P_Flavour",
  "P_Category",
  "P_SubCategory",
  "P_Beer_Type",
  "P_Pack_Size",
  "P_Price_Segment",
  "P_ABV",
  "P_Pack_Return_Type",
  "P_MACO_per_HL",
  "P_Launch_Period",
];

export const getColumns = (
  isShared: boolean,
  editName: CellComponent,
  preCheckBox: CellComponent,
  actionbuttons: CellComponent
): ColumnDef<ScenarioType>[] => {
  const columns: ColumnDef<ScenarioType>[] = [
    {
      header: CheckboxHeader,
      accessorKey: "checkbox",
      cell: preCheckBox,
    },
    {
      header: "Scenario Name",
      accessorKey: "name",
      cell: editName,
    },
    {
      header: "Created On",
      accessorKey: "date",
      cell: formatDate,
    },

    {
      header: "Country",
      accessorKey: "Country",
      cell: TableCell,
    },

    ...(isShared
      ? [
          {
            header: "Created By",
            accessorKey: "created_by",
            cell: TableCell,
          },
        ]
      : []),
    {
      header: "Brand",
      accessorKey: "brand",
      cell: TableCell,
    },
    {
      header: "Total Volume",
      accessorKey: "incremental_nr",
      cell: TableCell,
    },
    {
      header: "Incremental Volume",
      accessorKey: "incremental_volume_%",
      cell: TableCell,
    },
    {
      header: IncMaCoHeader,
      accessorKey: "incremental_maco",
      cell: TableCell,
    },
    {
      header: "Actions",
      accessorKey: "actions",
      cell: actionbuttons,
    },
  ];
  return columns;
};
export const RegionalColumn = [
  {
    header: "Districts",
    accessorKey: "R_Districts",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "POC Segments",
    accessorKey: "R_POC_Segments",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "NR per HL (USD)",
    accessorKey: "R_Price_per_HL",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "Distribution %",
    accessorKey: "R_Distribution",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
];

export const BrazilRegionalColumn = [
  {
    header: "Regions",
    accessorKey: "R_Regions",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "Channels",
    accessorKey: "R_Channels",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "NR per HL (USD)",
    accessorKey: "R_Price_per_HL",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "Distribution %",
    accessorKey: "R_Distribution",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
];
