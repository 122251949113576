import Papa from "papaparse";

import { categoryDistributionBR, categoryDistributionRSA } from "./Constants";
import {
  Category,
  CountryData,
  CPIData,
  LabelsGridProps,
  SelectedValues,
} from "./types";
import { Text } from "@chakra-ui/react";

interface InnoData {
  year: number;
  price_per_hl: number;
}

interface AdjustedPrice {
  year: number;
  adjusted_price: number;
}


const checkAttributes = (
  productAttributes: any,
  regionalAttributes: any,
  modelWarning: any
) => {
  const matchesSet = new Set();
  const filters = modelWarning[0];
  const filter = filters[productAttributes.P_Category];

  if (!filters) {
    return [];
  }
  if (productAttributes.P_ABV > filter.abv.toFixed(2)) {
    matchesSet.add("ABV");
  }
  for (const regionalAttr of regionalAttributes) {
    if (regionalAttr.R_Price_per_HL > filter.price_per_hl.toFixed(2)) {
      matchesSet.add("NR per HL (USD)");
    }
    if (regionalAttr.R_Distribution > filter.distribution.toFixed(2)) {
      matchesSet.add("Distribution %");
    }
  }

  return Array.from(matchesSet);
};

export const stopScenario = (regionalAttributes: any) => {
  for (const regionalAttr of regionalAttributes) {
    if (regionalAttr.R_Distribution > 100) {
      return true;
    }
  }
  return false;
};
interface ProductAttributes {
  P_Category: string; // Assuming it's a string, adjust as necessary
  P_Launch_Period: number; // Assuming it's a number, adjust as necessary
}

interface SelectedFilter {
  Product_attributes: ProductAttributes;
}
interface AdjustDistributionProps {
  category: string;
  distribution: number;
  innoStartYear: number;
}

export const adjustDistribution = ({
  category,
  distribution,
  innoStartYear,
}: AdjustDistributionProps) => {
  const country = sessionStorage.getItem("country");
  const categoryDistribution: Record<string, number> =
    country === "South Africa"
      ? categoryDistributionRSA
      : categoryDistributionBR;

  const growthRate = (categoryDistribution[category] || 0) / 100; // Growth rate for the given category

  // Create data for 3 consecutive years with the initial distribution
  const data = [
    { year: innoStartYear, category, distribution },
    { year: innoStartYear + 1, category, distribution }, // Increment for year 2
    { year: innoStartYear + 2, category, distribution }, // Increment for year 3
    // { year: innoStartYear + 3, category, distribution }, // Increment for year 3
  ];

  const adjustedValues = data.map((row) => {
    if (row.year === innoStartYear) {
      return row.distribution;
    } else {
      const yearsSinceStart = row.year - innoStartYear;
      const adjustedDistribution =
        row.distribution * (1 + growthRate) ** yearsSinceStart;
      return Math.min(adjustedDistribution, 100); // Cap at 100
    }
  });

  return adjustedValues;
};

// =================================================================================================
export const createCpiYearMap = (
  cpiDataDict: CPIData[]
): Record<number, number> =>
  cpiDataDict.reduce((acc: Record<number, number>, item: CPIData) => {
    const year = new Date(item.date).getFullYear();
    acc[year] = parseFloat(item.value);
    return acc;
  }, {});

const adjustPrice = (
  row: InnoData,
  innoStartYear: number,
  year1: number,
  cpiDataDict: CPIData[]
): number => {
  // const cpiYearMap = cpiDataDict.reduce(
  //   (acc: Record<number, number>, item: CPIData) => {
  //     const year = new Date(item.date).getFullYear();
  //     acc[year] = parseFloat(item.value);
  //     return acc;
  //   },
  //   {}
  // );
  const cpiYearMap = createCpiYearMap(cpiDataDict);

  if (year1 === innoStartYear) {
    return row.price_per_hl;
  } else {
    const years = Array.from(
      { length: row.year - innoStartYear },
      (_, i) => innoStartYear + i + 1
    );
    let adjustmentFactor = 1;

    for (const year of years) {
      if (cpiYearMap[year] !== undefined) {
        adjustmentFactor *= 1 + cpiYearMap[year] / 100;
      }
    }

    const basePrice = row.price_per_hl;
    return basePrice * adjustmentFactor;
  }
};

export const loadCPIData = async (): Promise<CPIData[]> => {
  const country = sessionStorage.getItem("country");

  try {
    const response = await fetch(
      country === "South Africa"
        ? "../../../files/RSA_CPI_data.csv"
        : "../../../files/BR_CPI_data.csv"
    );
    const csvData = await response.text();
    return new Promise((resolve, reject) => {
      Papa.parse(csvData, {
        header: true, // If your CSV has headers
        complete: (result) => {
          resolve(result.data as CPIData[]);
        },
        skipEmptyLines: true, // Skip empty lines in the CSV
        error: (error) => {
          reject(error);
        },
      });
    });
  } catch (error) {
    console.error("Error loading CPI data:", error);
    return [];
  }
};

const calculateAdjustedPrices = async (
  innoData: InnoData[],
  cpiDataDict: CPIData[]
): Promise<AdjustedPrice[]> => {
  if (innoData.length === 0 || cpiDataDict.length === 0) return [];

  const innoStartYear = Math.min(...innoData.map((item) => item.year));
  const prices = innoData.map((row) => ({
    year: row.year,
    adjusted_price: adjustPrice(row, innoStartYear, row.year, cpiDataDict),
  }));

  return prices;
};
export const getAdjustedPrices = async (
  firstYear: number,
  pricePerHl: number,
  numberOfYears: number
): Promise<number[]> => {
  const cpiDataDict = await loadCPIData();

  // Generate exampleInnoData dynamically
  const exampleInnoData: InnoData[] = Array.from(
    { length: numberOfYears },
    (_, i) => ({
      year: firstYear + i,
      price_per_hl: pricePerHl,
    })
  );

  const adjustedPrices = await calculateAdjustedPrices(
    exampleInnoData,
    cpiDataDict
  );
  return adjustedPrices.map((price) =>
    parseFloat(price.adjusted_price.toFixed(2))
  );
};

// =================================================================================================
// =================================================================================================

export const getExactDate = (quarterString: string) => {
  // Extract year and quarter
  const [year, quarter] = quarterString.split(" ");

  // Map quarters to start dates
  const quarterStartDates = {
    Q1: "01-01",
    Q2: "04-01",
    Q3: "07-01",
    Q4: "10-01",
  };

  // Get the exact start date of the quarter
  const dateString = `${year}-${quarterStartDates[quarter]}`;

  // Convert to a Date object if necessary
  const exactDate = new Date(dateString);

  return exactDate;
};
export const getBrDate = (innoStartYear: string): Date => {
  // Extract the year and month parts
  const [year, month] = innoStartYear.split("/").map(Number);

  // Convert the year and month parts to a Date object (first day of the given month)
  const dateObject = new Date(year, month - 1); // month is 0-indexed

  // Return the Date object
  return dateObject;
};
export const updateAttributes = (
  values: SelectedValues,
  startDate: string,
  previousStartDate: string,
  selectedCountry: string
) => {
  // const startDate = String(values.Product_attributes?.P_Launch_Period ?? "");

  if (startDate !== "" && startDate !== previousStartDate) {
    const date =
      selectedCountry === "South Africa"
        ? getExactDate(startDate)
        : getBrDate(startDate);

    const startYear = date.getFullYear();

    const updatedRegionalAttributes = values.Regional_attributes.map((attr) => {
      const price = attr.R_Price_by_year as
        | { [key: string]: number }
        | undefined;
      const DefaultPrice = attr.R_Default_Price as
        | { [key: string]: number }
        | undefined;
      const DefaultDistribution = attr.R_Default_Distribution as
        | { [key: string]: number }
        | undefined;
      const distribution = attr.R_Distribution_by_year as
        | { [key: string]: number }
        | undefined;

      const updatedAttr = { ...attr };

      if (price) {
        const updatedPrice: { [key: string]: number } = {};
        Object.keys(price).forEach((year, index) => {
          updatedPrice[(startYear + index).toString()] = price[year] ?? 0;
        });
        updatedAttr.R_Price_by_year = updatedPrice;
        // updatedAttr.R_Default_Price = updatedPrice;
      }
      if (DefaultPrice) {
        const updatedPrice: { [key: string]: number } = {};
        Object.keys(DefaultPrice).forEach((year, index) => {
          // const [start, end] = year.split(" - ").map(Number);
          const newStartYear = startYear + index;
          const newEndYear = newStartYear + 1;
          if (date.getMonth() === 0) {
            updatedPrice[`${newStartYear}`] = DefaultPrice[year] ?? 0;
          } else {
            updatedPrice[`${newStartYear} - ${newEndYear}`] =
              DefaultPrice[year] ?? 0;
          }
        });
        // updatedAttr.R_Price_by_year = updatedPrice;
        updatedAttr.R_Default_Price = updatedPrice;
      }
      if (DefaultDistribution) {
        const updatedDistribution: { [key: string]: number } = {};
        Object.keys(DefaultDistribution).forEach((year, index) => {
          // const [start, end] = year.split(" - ").map(Number);
          const newStartYear = startYear + index;
          const newEndYear = newStartYear + 1;
          if (date.getMonth() === 0) {
            updatedDistribution[`${newStartYear}`] =
              DefaultDistribution[year] ?? 0;
          } else {
            updatedDistribution[`${newStartYear} - ${newEndYear}`] =
              DefaultDistribution[year] ?? 0;
          }
        });
        // updatedAttr.R_Price_by_year = updatedPrice;
        updatedAttr.R_Default_Distribution = updatedDistribution;
      }

      if (distribution) {
        const updatedDistribution: { [key: string]: number } = {};
        Object.keys(distribution).forEach((year, index) => {
          updatedDistribution[(startYear + index).toString()] =
            distribution[year] ?? 0;
        });
        updatedAttr.R_Distribution_by_year = updatedDistribution;
        // updatedAttr.R_Default_Distribution = updatedDistribution;
      }

      return updatedAttr;
    });
    return {
      ...values,
      Regional_attributes: updatedRegionalAttributes,
    };
  }

  return values;
};

export const getDateByCountry = (newcountry: string, date: string): Date =>
  newcountry === "South Africa" ? getExactDate(date) : getBrDate(date);

export const getLabelBgColor = (label: string) => {
  switch (label) {
    case "Entry":
      return "#FEFBE0";
    case "Frequency":
      return "#FFF8BC";
    case "Intensity":
      return "#F4E776";
    default:
      return "#FFFFFF"; // Default color if none of the cases match
  }
};

export const filterDefaultValues = (data: CountryData): CountryData => {
  const totalMarket = data["total market"] || [];
  const sortedData = Array.from(totalMarket).sort((a, b) => {
    if (a.type === "Default") return -1;
    if (b.type === "Default") return 1;
    return 0;
  });
  return {
    country: data.country,
    "total market": sortedData,
  };
};

export const moveAbiToSecondPosition = (items: Category[]) => {
  // Find the index of the object with "dataKey": "abi"
  const abiIndex = items.findIndex((item) => item.dataKey === "abi");

  if (abiIndex !== -1) {
    // Remove the object from its current position
    const [abiItem] = items.splice(abiIndex, 1);

    // Insert the object at the second position
    if (abiItem) {
      items.splice(1, 0, abiItem);
    }
  }

  return items;
};

export const getBackgroundColorCat = (category: {
  type?: string;
  segment?: string;
}): string => {
  const type = category.type ?? ""; // Provide a default value for type

  if (type === "Default" && category.segment === "CORE") {
    return "#CCEBFB";
  }
  if (type === "Default" && category.segment === "CORE+") {
    return "#E5CCFF";
  }
  if (type === "Default" && category.segment === "P / SP") {
    return "#ffc9d6";
  }
  if (type === "CORE" || category.segment === "CORE") {
    return "#E6F5FD";
  }
  if (type === "CORE+" || category.segment === "CORE+") {
    return "#F3EBFC";
  }
  if (type === "P / SP" || category.segment === "P / SP") {
    return "#FDE6EB";
  }
  // Add more conditions as needed
  if (type === "Default") {
    return "#DCDCDC"; // Default color if colors array is empty
  }
  return "#F2F2F2";
};

export default checkAttributes;
