import { useAuthenticatedQuery } from "innovate/api/api";
import Loader from "innovate/components/common/Loader";
import { fixed } from "innovate/utils/Constants";
import React, {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface ApiResponse {
  user: {
    username: string;
    email: string;
    role: string;
  };
  length: number;
  approval_status: string;
  success: boolean;
}

export const ApprovalContext = createContext<{
  isApproved: boolean | null;
  isUser: boolean | null;
  userName: string;
  userType: string;
  setUserName: React.Dispatch<React.SetStateAction<string>>;
  SetIsUser: React.Dispatch<React.SetStateAction<boolean | null>>;
  setIsApproved: React.Dispatch<React.SetStateAction<boolean | null>>;
  isLoading: boolean;
}>({
  isApproved: null,
  setIsApproved: () => {},
  isLoading: true,
  isUser: null,
  userType: "",
  SetIsUser: () => {},
  userName: "",
  setUserName: () => {},
});

interface ApprovalProviderProps {
  children: ReactNode;
}

const ApprovalProvider: React.FC<ApprovalProviderProps> = ({ children }) => {
  const { data, error, isLoading } = useAuthenticatedQuery<ApiResponse>(
    ["approvalStatus", "approvalStatusData"],
    {
      url: `${fixed}approvals/status`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    },
  );

  const navigate = useNavigate();
  const location = useLocation();
  const [isUser, SetIsUser] = useState<boolean | null>(null);
  const [userName, setUserName] = useState<string>("");
  const [userType, setUserType] = useState<string>("");

  const [isApproved, setIsApproved] = useState<boolean | null>(null);
  const [ApiError, setApiError] = useState<string>("");

  useEffect(() => {
    if (!data?.success) return;
    if (!data?.data) {
      navigate("/access");
      return;
    }
    if (data?.data?.length === 0) {
      navigate("/access");
      return;
    }
    SetIsUser(true);
    setUserType(data.data.user.role);
    setUserName(data.data.user.username);
    const approvalStatus = data.data.approval_status === "1";
    setIsApproved(approvalStatus);

    if (!approvalStatus) {
      navigate("/access");
    }
  }, [data, location.pathname, navigate]);

  const value = useMemo(
    () => ({
      isApproved,
      setIsApproved,
      isLoading,
      isUser,
      SetIsUser,
      userName,
      setUserName,
      ApiError,
      setApiError,
      userType,
    }),
    [isApproved, isLoading, isUser, userName, ApiError, userType],
  );

  if (isLoading) return <Loader dH="90vh" dW="20vw" />;
  if (error) return `Error: ${error.message}`;
  if (data && !data.success) return "Server Error Page here";

  return (
    <ApprovalContext.Provider value={value}>
      {children}
    </ApprovalContext.Provider>
  );
};

export default ApprovalProvider;
