import {
  AbsoluteCenter,
  Box,
  Container,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import CompareView from "innovate/assets/images/CompareView.svg";
import GlobalView from "innovate/assets/images/GlobalView.svg";
import homeBackground from "innovate/assets/images/homeBackground.png";
import keyboardBackspace from "innovate/assets/images/keyboard_backspace.png";
import MarketView from "innovate/assets/images/MarketView.svg";
import Header from "innovate/components/common/Header";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./InnoExplore.css";

const InnoExplore: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Box zIndex={9} mb={5}>
      <Header />

      <Flex
        bgImage={homeBackground}
        bgSize="cover"
        bgPosition="center"
        h="195px"
        position="relative"
        p={10}
      >
        <Box>
          <Link to="/">
            <Image src={keyboardBackspace} />
          </Link>
        </Box>
        <AbsoluteCenter textAlign="center">
          <Text
            color="#797979"
            fontWeight={700}
            fontSize={{ base: "14px", md: "16px", lg: "16px" }}
            as="b"
            pb={6}
          >
            <Text color="#FFFFFF" as="b">
              Welcome to InnoExplore
            </Text>
          </Text>
          <Text
            color="rgba(255, 255, 255, 0.90);"
            fontWeight="600"
            fontSize={{ base: "12px", md: "12px", lg: "12px" }}
            mt="3"
            p={0}
          >
            InnoExplore module helps you analyze all of our brands against all
            the other competitor brands in different countries and regions
          </Text>
        </AbsoluteCenter>
      </Flex>
      <Box className="analysis">
        <Container w="90%" h="60vh">
          <Flex
            justifyContent="center"
            direction="column"
            alignItems="center"
            mt="5"
          >
            <Text fontWeight="700">Select a view to get started</Text>
            <Box
              mt="1"
              h="3px"
              w="10%"
              bg="linear-gradient(225.02deg, #F1D513 10.28%, #D0A23B 96.62%)"
            />
          </Flex>
          <Flex justifyContent="center" alignItems="center" mt="10" gap="8">
            {/* Global View Box */}
            <Box
              position="relative"
              w="266px"
              h="150px"
              bg="white"
              boxShadow="lg"
              borderRadius="lg"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap="4"
              overflow="hidden"
              _hover={{ cursor: "pointer" }}
            >
              <Image w="36px" src={GlobalView} alt="GlobalView" />
              <Text fontWeight="600" fontSize="16px">
                Global View
              </Text>

              {/* Hover Overlay */}
              <Box
                position="absolute"
                top="0"
                left="0"
                w="100%"
                h="100%"
                bg="blackAlpha.800"
                color="#D0A23B"
                display="flex"
                justifyContent="center"
                alignItems="center"
                opacity="0"
                onClick={() => navigate("/global-view")}
                _hover={{
                  opacity: 1,
                  backgroundColor: "black",
                  color: "#D0A23B",
                }}
                p="4"
                textAlign="center"
              >
                <Text fontSize="14px" fontWeight="700" color="#D0A23B">
                  Global View dolor sit amet, consectetur adipiscing elit.
                </Text>
              </Box>
            </Box>

            {/* Market View Box */}
            <Box
              position="relative"
              w="266px"
              h="150px"
              bg="white"
              boxShadow="lg"
              borderRadius="lg"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap="4"
              // overflow="hidden"
              _hover={{ cursor: "not-allowed" }}
            >
              <Image w="36px" src={MarketView} alt="MarketView" />
              <Text fontWeight="600" fontSize="16px">
                Market View
              </Text>

              {/* Hover Overlay */}
              {/* <Box
                position="absolute"
                top="0"
                left="0"
                w="100%"
                h="100%"
                bg="blackAlpha.800"
                color="#D0A23B"
                display="flex"
                justifyContent="center"
                alignItems="center"
                opacity="0"
                _hover={{
                  opacity: 1,
                  backgroundColor: "black",
                  color: "#D0A23B",
                }}
                p="4"
                textAlign="center"
              >
                <Text fontSize="14px" fontWeight="700" color="#D0A23B">
                  Market View dolor sit amet, consectetur adipiscing elit.
                </Text>
              </Box> */}
            </Box>

            {/* Compare View Box */}
            <Box
              position="relative"
              w="266px"
              h="150px"
              bg="white"
              boxShadow="lg"
              borderRadius="lg"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap="4"
              // overflow="hidden"
              _hover={{ cursor: "not-allowed" }}
            >
              <Image w="36px" src={CompareView} alt="CompareView" />
              <Text fontWeight="600" fontSize="16px">
                Compare View
              </Text>

              {/* Hover Overlay */}
              {/* <Box
                position="absolute"
                top="0"
                left="0"
                w="100%"
                h="100%"
                bg="blackAlpha.800"
                color="white"
                display="flex"
                justifyContent="center"
                alignItems="center"
                opacity="0"
                _hover={{
                  opacity: 1,
                  backgroundColor: "black",
                  color: "#595959",
                }}
                p="4"
                textAlign="center"
              >
                <Text fontSize="14px" fontWeight="700" color="#D0A23B">
                  Compare View dolor sit amet, consectetur adipiscing elit.
                </Text>
              </Box> */}
            </Box>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

export default InnoExplore;
