import {
  Box,
  Button,
  Container,
  Flex,
  Image,
  Switch,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import info from "innovate/assets/images/info.svg";
import vectorback from "innovate/assets/images/vectorback.svg";
import Header from "innovate/components/common/Header";
import { Option } from "innovate/components/Inputs/MultiSelectInput";
import SelectInput from "innovate/components/Inputs/SelectInput";
import {
  updatedExploreAbs,
  updatedExplorePerWithPercentage,
} from "innovate/utils/Constants";
import React, { useState } from "react";
import { Link } from "react-router-dom";

// eslint-disable-next-line no-restricted-imports
import ExploreCountry from "./ExloperCountry";

// import ExploreCountry from "./ExloperCountry";

const GlobalView: React.FC = () => {
  const zone = ["Africa"];
  const market = ["South Africa"];
  const years = [2024, 2023, 2022, 2021, 2020];
  const aggregates = ["Volume", "Net Revenue"];
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [innoData, setInnoData] = useState(updatedExplorePerWithPercentage);
  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setInnoData(updatedExploreAbs);
    } else {
      setInnoData(updatedExplorePerWithPercentage);
    }
    setIsSwitchOn(event.target.checked);
  };

  //   const innoData = innoExplore;
  const createData = <T extends string | number>(
    items: T[],
  ): { value: T; label: string }[] =>
    items.map((item: T) => ({
      value: item,
      label: item.toString(),
    }));
  const zoneData = createData(zone);
  const marketData = createData(market);
  const yearData = createData(years);
  const aggregatedData = createData(aggregates);
  const [selectedZone, setSelectedZone] = useState<Option | null>(null);
  const [selectedMarket, setSelectedMarket] = useState<Option | null>(null);
  const [selectedYear, setSelectedYear] = useState<Option | null>(null);
  const [selectedAggregate, setSelectedAggregate] = useState<Option | null>(
    null,
  );

  const handleFilter = (filterType: string, selectedOptions: Option | null) => {
    if (filterType === "zone") {
      setSelectedZone(selectedOptions);
    }

    if (filterType === "market") {
      setSelectedMarket(selectedOptions);
    }
    if (filterType === "year") {
      setSelectedYear(selectedOptions);
    }
    if (filterType === "aggregate") {
      setSelectedAggregate(selectedOptions);
    }
    // Handle other filter types if needed
  };
  const [isLargerThan1250px] = useMediaQuery("(min-width: 1250px)");
  const [isLargerThan1100px] = useMediaQuery("(min-width: 1100px)");
  const customWidth = isLargerThan1250px ? "150px" : "130px"; // Adjust the widths as needed
  return (
    <>
      <Header />
      <Container mb={10} w="96%">
        <Flex mt={6}>
          <Link to="/inno-explore">
            <Text
              ml={2}
              //   variant="custom"
              fontWeight="bold"
              textDecorationStyle="solid"
              fontSize="14px"
            >
              &lt; &nbsp; Overview &nbsp;\
            </Text>
          </Link>
          <Flex>
            <Text
              ml={2}
              //   variant="custom"
              fontSize="14px"
              fontWeight={800}
              textDecoration="underline"
            >
              {" "}
              Global View
            </Text>
          </Flex>
        </Flex>
        <Box
          position="relative"
          p={4}
          bg="#FFFFFF"
          my={10}
          borderRadius="md"
          boxShadow="sm"
          _before={{
            content: '""',
            position: "absolute",
            top: "15%", // Adjust top position as needed
            zIndex: -1,
            left: -2, // Offset to align with the main box
            bottom: "20%", // Adjust bottom position as needed
            height: "70%", // Adjust height if needed
            width: "20px", // Adjust width as needed
            backgroundColor: "#F1B800", // Yellow color
            borderRadius: "10px 0 0 10px", // Curved borders on top-left and bottom-left
          }}
        >
          <Flex align="center" justifyContent="space-between">
            {/* Zones Dropdown */}
            <Flex gap={3} justifyContent="space-between">
              <Flex direction="column" gap={4}>
                <Flex align="center" gap={1}>
                  <Text variant="custom">Zone</Text>
                  <Image height="16px" src={info} opacity={0.5} />
                </Flex>
                <SelectInput
                  name="globalInput"
                  id="globalZoneInput"
                  options={zoneData}
                  customWidth={customWidth}
                  isRequired
                  value={selectedZone}
                  onChange={(selectedOptions) =>
                    handleFilter("zone", selectedOptions)
                  }
                />
              </Flex>
              <Flex direction="column" gap={4}>
                <Flex align="center" gap={1}>
                  <Text variant="custom">Markets</Text>
                  <Image height="16px" src={info} opacity={0.5} />
                </Flex>
                {/* Markets Dropdown */}
                <SelectInput
                  name="globalInput"
                  id="globalMarketsInput"
                  options={marketData}
                  customWidth={customWidth}
                  isRequired
                  value={selectedMarket}
                  onChange={(selectedOptions) =>
                    handleFilter("market", selectedOptions)
                  }
                />
              </Flex>
              <Flex direction="column" gap={4}>
                <Flex align="center" gap={1}>
                  <Text variant="custom">Years</Text>
                  <Image height="16px" src={info} opacity={0.5} />
                </Flex>
                <Box>
                  <SelectInput
                    name="globalInput"
                    id="globalYearsInput"
                    options={yearData}
                    customWidth={customWidth}
                    isRequired
                    value={selectedYear}
                    onChange={(selectedOptions) =>
                      handleFilter("year", selectedOptions)
                    }
                  />
                </Box>
              </Flex>
              <Flex direction="column" gap={4}>
                <Flex align="center" gap={1}>
                  <Text variant="custom">Aggregate</Text>
                  <Image height="16px" src={info} opacity={0.5} />
                </Flex>
                <Box>
                  <SelectInput
                    name="globalInput"
                    id="globalAggregateInput"
                    options={aggregatedData}
                    customWidth={customWidth}
                    isRequired
                    value={selectedAggregate}
                    onChange={(selectedOptions) =>
                      handleFilter("aggregate", selectedOptions)
                    }
                  />
                </Box>
              </Flex>
              <Flex direction="column" gap={4}>
                <Flex align="center" gap={1}>
                  <Text variant="custom">Show</Text>
                  <Image height="16px" src={info} opacity={0.5} />
                </Flex>
                {/* Toggle for Percentage */}
                <Flex
                  align="center"
                  gap={2}
                  p={2}
                  border="1px solid #1414141f"
                  borderRadius="8px"
                >
                  <Text variant="custom">Percentage(%)</Text>
                  <Switch
                    size="sm"
                    colorScheme="teal"
                    isChecked={isSwitchOn}
                    onChange={handleSwitchChange}
                  />
                  <Text variant="custom">Absolute (kHL)</Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex w="20%">
              {/* Buttons */}
              <Flex direction="column" flex="1" gap={1}>
                <Button
                  variant="green"
                  border="none"
                  color="white"
                  py={4}
                  size="sm"
                >
                  <Image src={vectorback} m={1} />
                  <Text color="#FFFFFF">Export to Excel</Text>
                </Button>
                <Flex gap={4}>
                  <Button
                    variant="outline"
                    color="white"
                    size="sm"
                    py={4}
                    flex="1"
                  >
                    Reset
                  </Button>
                  <Button
                    variant="outline"
                    color="white"
                    size="sm"
                    py={4}
                    flex="1"
                  >
                    Apply
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Box>
        <Flex gap="5" direction="column">
          {innoData.map((data) => (
            <ExploreCountry data={data} isSwitchOn={isSwitchOn} />
          ))}
        </Flex>
      </Container>
    </>
  );
};

export default GlobalView;
