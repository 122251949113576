import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
} from "@chakra-ui/react";
import NigeriaFlag from "innovate/assets/flags/NigeriaFlag.svg";
import SAFLAG from "innovate/assets/flags/RSAFLAG.svg";

import {
  createBrandLabel,
  findMatchingItems,
  getBrandLabelColor,
  getLabelContent,
  LabelsGrid,
  sortByBrandCategory,
} from "innovate/utils/ExploreHelper/ExploreHelper";
import {
  filterDefaultValues,
  getBackgroundColorCat,
  moveAbiToSecondPosition,
} from "innovate/utils/Helper";
import { Category, CountryData } from "innovate/utils/types";
import React, { useEffect, useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

import InnoExploreGrid from "./InnoExploreTable";
import Loader from "innovate/components/common/Loader";

interface ExploreCountryProps {
  data: CountryData;
  isSwitchOn: boolean;
}

const ExploreCountry: React.FC<ExploreCountryProps> = ({
  data,
  isSwitchOn,
}) => {
  const [filteredData, setFilteredData] = useState<CountryData | null>(null);

  const [expandedKeys, setExpandedKeys] = useState<Set<string>>(new Set());
  useEffect(() => {
    setExpandedKeys(new Set());
  }, [isSwitchOn]);

  useEffect(() => {
    const defaultData = filterDefaultValues(data);
    setFilteredData(defaultData);
  }, [data]);

  const updateFilteredData = (
    key: string,
    masterKey: string,
    isSubCategory: boolean
  ) => {
    const doesKeyExist =
      filteredData?.["total market"]?.some(
        (cat) =>
          cat.segment === key &&
          (isSubCategory
            ? cat.type !== "Default" && cat.dataKey === masterKey
            : true)
      ) ?? false;
    if (doesKeyExist) {
      // Remove entries with the same segment
      setFilteredData((prevData) => {
        if (!prevData) return prevData;

        const updatedData = { ...prevData };
        updatedData["total market"] = prevData["total market"].filter(
          (cat) =>
            cat.segment !== key ||
            (isSubCategory && cat.type === "Default" && cat.segment === key) ||
            (isSubCategory && cat.dataKey !== masterKey),
        );
        return updatedData;
      });
      return;
    }

    const matchingItems = findMatchingItems(
      key,
      masterKey,
      isSubCategory,
      data
    );

    // Update filteredData with matching items
    if (isSubCategory) {
      setFilteredData((prevData) => {
        if (!prevData) {
          return prevData; // Return early if prevData is null
        }
        const updatedData = { ...prevData };
        const totalMarket = updatedData["total market"] || [];

        // Ensure uniqueness in matchingItems
        const uniqueItems = matchingItems.filter(
          (item) =>
            !totalMarket.some(
              (marketItem) =>
                marketItem.dataKey === item.dataKey &&
                marketItem.segment === item.segment &&
                marketItem.type === item.type
            )
        );
        const sortedItems = sortByBrandCategory(uniqueItems);
        const keyIndex = totalMarket.findIndex(
          (category) =>
            category.dataKey === masterKey &&
            category.segment === key &&
            category.type === "Default"
        );

        if (keyIndex !== -1) {
          // Insert unique items at the correct position
          totalMarket.splice(keyIndex + 1, 0, ...sortedItems);
        } else {
          // Add unique items to the end
          totalMarket.push(...sortedItems);
        }

        updatedData["total market"] = totalMarket;
        return updatedData;
      });
    } else {
      setFilteredData((prevData) => {
        if (!prevData) {
          return prevData; // Return early if prevData is null
        }
        const updatedData = { ...prevData };
        const totalMarket = updatedData["total market"] || [];

        // Check if matching items already exist to prevent duplicates
        const existingItems = totalMarket.some(
          (cat) => cat.segment === key && cat.type === "Default"
        );

        if (!existingItems) {
          const keyIndex = totalMarket.findIndex(
            (category) => category.type === key
          );
          const updatedMatchingItems = moveAbiToSecondPosition(matchingItems);

          if (keyIndex !== -1) {
            // Insert matching items just after the key
            totalMarket.splice(keyIndex + 1, 0, ...updatedMatchingItems);
          } else {
            // If key is not found, append matching items at the end
            totalMarket.push(...updatedMatchingItems);
          }

          updatedData["total market"] = totalMarket;
        }

        return updatedData;
      });
    }
  };

  const handleSubCategoryClick = (key: string, masterKey: string) => {
    updateFilteredData(key, masterKey, true);
  };

  const handlesegmentClick = (key: string) => {
    updateFilteredData(key, "", false);
  };

  const handleOpenCat = (category: Category, id: string) => {
    setExpandedKeys((prevKeys) => {
      const newKeys = new Set(prevKeys);
      if (newKeys.has(id)) {
        newKeys.delete(id);
      } else {
        newKeys.add(id);
      }
      return newKeys;
    });
    const { type } = category;
    const { segment } = category;

    if (type && type !== "Default" && !segment) {
      handlesegmentClick(type);
    }
    if (segment && segment !== "") {
      const masterKey = category?.dataKey ?? "";
      if (masterKey && masterKey !== "") {
        if (type !== "Default") return;
        handleSubCategoryClick(segment, masterKey);
      }
    }
  };

  if (!filteredData) {
    return <Loader dH="40vh" dW="16vw" />;
  }
  return (
    <Box p={4} bg="white" boxShadow="md" borderRadius="md" mb={10}>
      <Flex alignItems="flex-start" mt={2} gap={4} mb={2}>
        <Flex alignItems="flex-start" direction="column" width="15%">
          <Flex className="globalTableFilter" gap="2%">
            <Image
              src={filteredData.country === "SAF" ? SAFLAG : ""}
              alt={filteredData.country}/>
            <Text variant="custom" ml={1}>
              {filteredData.country}
            </Text>
          </Flex>
          <Flex
            mb={2}
            h="34px"
            padding="8px"
            width="100%"
            borderRadius="md"
            fontWeight="600"
            justifyContent="space-between"
            alignItems="center"
            cursor="pointer"
            height="35px"
            bg="#DCDCDC"
          >
            <Text variant="custom" ml={1} isTruncated>
              Total Market
            </Text>
            <FiChevronUp />
          </Flex>

          {filteredData["total market"]
            .filter(
              (category) => category.dataKey || category.type !== "Default"
            )
            .map((category, index) => (
              <Flex
                key={`total_market-${index}`}
                mb={2}
                h="34px"
                id={`total_market-${index}`}
                padding="8px"
                width="100%"
                borderRadius="md"
                fontWeight="600"
                justifyContent="space-between"
                alignItems="center"
                cursor="pointer"
                height="35px"
                bg={getBackgroundColorCat(category)}
                onClick={() => handleOpenCat(category, `total_market-${index}`)} // Pass the id here
              >
                <Flex dir="row">
                  {category["Brand Category"] !== "" && (
                    <Text
                      variant="custom"
                      bg={getBrandLabelColor(category["Brand Category"] ?? "")}
                      p={1}
                      mr={1}
                      borderRadius="50%"
                      width="16px"
                      height="16px"
                      display="flex"
                      fontSize="10px"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {category["Brand Category"]?.charAt(0)}
                    </Text>
                  )}
                  <Text variant="custom" ml={1} isTruncated>
                    {createBrandLabel(category)}
                  </Text>
                </Flex>
                {(category.type === "Default" || category.segment === "") &&
                  (expandedKeys.has(`total_market-${index}`) ? (
                    <FiChevronUp />
                  ) : (
                    <FiChevronDown />
                  ))}
              </Flex>
            ))}
        </Flex>
        <Flex direction="column" width="85%">
          {Object.entries(filteredData).map(([key, value]) => {
            if (!Array.isArray(value)) return null;
            return (
              <Box key={key}>
                {key === "total market" && (
                  <Flex gap={6} justifyContent="space-between" flexWrap="wrap">
                    {["Entry", "Frequency", "Intensity"].map((label, idx) => (
                      <Box
                        key={idx}
                        flex="1"
                        maxWidth="33%"
                        p={0}
                        minWidth="200px"
                      >
                        <Center
                          mb={2}
                          border="1px solid #D8D8D8"
                          p={1}
                          borderRadius="md"
                          bg={getBackgroundColorCat(label) ?? ""}
                        >
                          <Text variant="custom">{label}</Text>
                          <Box ml={2} px={1} bg="yellow.400" borderRadius="md">
                            <Text
                              variant="custom"
                              className="explorePack"
                              fontSize="10px"
                            >
                              {getLabelContent(label)}
                            </Text>
                          </Box>
                        </Center>
                        <LabelsGrid label={label} />
                      </Box>
                    ))}
                  </Flex>
                )}

                {value.map((category, index) => (
                  <Box key={index}>
                    {Object.entries(category).map(
                      ([categoryKey, categoryValue]) => {
                        if (!Array.isArray(categoryValue)) return null;
                        return (
                          <Box key={categoryKey} mb={2}>
                            <Grid
                              templateColumns="repeat(auto-fit, minmax(200px, 1fr))"
                              gap={6}
                            >
                              {categoryValue.map((entry, idx) => (
                                <GridItem key={idx} p={0}>
                                  <InnoExploreGrid
                                    data={entry}
                                    color={getBackgroundColorCat(category)}
                                  />
                                </GridItem>
                              ))}
                            </Grid>
                          </Box>
                        );
                      }
                    )}
                  </Box>
                ))}
              </Box>
            );
          })}
        </Flex>
      </Flex>
    </Box>
  );
};

export default ExploreCountry;
