import { SystemStyleObject } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useMemo } from "react";
import { ActionMeta } from "react-select";

// eslint-disable-next-line no-restricted-imports
import { type Option } from "./MultiSelectInput";

export type SelectInputProps = {
  name: string;
  id: string;
  options: Option[];
  customWidth: string;
  value: Option | null;
  onChange: (newValue: Option | null, actionMeta: ActionMeta<Option>) => void;
  disabled?: boolean;
  placeholder?: string;
  dropdownIndicatorSx?: SystemStyleObject;
  isRequired?: boolean;
};

const SelectInput = ({
  name,
  id,
  options,
  value,
  onChange,
  disabled,
  customWidth,
  placeholder,
  isRequired,
  dropdownIndicatorSx,
}: SelectInputProps) => {
  const updatedVal = { value: "OTHERS", label: "OTHERS" };
  let selectedValue;
  if (placeholder === "OTHERS") {
    selectedValue = updatedVal;
  } else {
    selectedValue = value;
  }
  const getMinHeight = (n: string) => {
    if (n === "ResultPieChart") {
      return "auto";
    } else if (n === "globalInput") {
      return "35px";
    } else {
      return undefined;
    }
  };
  const fullOptions = useMemo(() => {
    if (
      name === "Brand" ||
      name === "Sub Brand" ||
      name === "Flavour" ||
      name === "Pack Size"
    ) {
      return [...options, { value: "OTHERS", label: "OTHERS" }];
    }
    return Array.from(options);
  }, [options, name]);

  return (
    <Select
      name={name}
      id={id}
      value={selectedValue}
      onChange={onChange}
      required={isRequired}
      isDisabled={disabled}
      options={fullOptions}
      chakraStyles={{
        menuList: (baseStyles) => ({
          ...baseStyles,
          maxHeight: "168px",
        }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          fontWeight: "600",
          fontSize: "10px",
          textAlign: name === "AccessSelect" ? "left" : "center",
          color: "#1E1E1ECC",
          bg: "white",
          width: customWidth,
          padding: "0 16px 0",
          minHeight: getMinHeight(name),
          borderColor: state.isFocused
            ? "primaryBlack !important"
            : "border !important",
          borderRadius: "8px",
          boxShadow: "none !important",
        }),
        input: (baseStyles) => ({
          ...baseStyles,
          fontSize: "10px", // Increase font size
          fontWeight: "600", // Increase font weight
        }),
        container: (baseStyles) => ({
          ...baseStyles,
          px: 0,
        }),
        valueContainer: (baseStyles) => ({
          ...baseStyles,
          px: "0",
          fontSize: "10px",
          fontWeight: "600",
        }),
        dropdownIndicator: (baseStyles) => ({
          ...baseStyles,
          px: "2px",
          bg: "none",
          cursor: "pointer",
          dropdownIndicatorSx,
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          color: "#1E1E1ECC !important",
          fontSize: "10px",
          textTransform: "uppercase",
          fontWeight: state.isSelected ? "700" : "600",
          background: state.isSelected
            ? "linear-gradient(rgba(245, 224, 3, 0.5), rgba(229, 182, 17, 0.5)) !important"
            : "none",
          _hover: {
            color: "#1E1E1ECC",
            bg: "linear-gradient(rgba(245, 224, 3, 0.5), rgba(229, 182, 17, 0.5))",
          },
        }),
        placeholder: (baseStyles: any) => ({
          ...baseStyles,
          color: name === "globalInput" ? "black" : "#1E1E1ECC",
        }),
      }}
      components={{
        IndicatorSeparator: null,
      }}
      placeholder={placeholder}
    />
  );
};
SelectInput.defaultProps = {
  disabled: false,
  placeholder: "Select",
  dropdownIndicatorSx: {},
  isRequired: false,
};
export default SelectInput;
