import { Center, Grid, GridItem, Image, Text } from "@chakra-ui/react";
import abiBrand from "innovate/assets/images/abiBrand.svg";
import beerIcon from "innovate/assets/images/globalBeer.svg";
import bottleIcon from "innovate/assets/images/globalBottle.svg";
import heineken from "innovate/assets/images/heineken.svg";
import {
  getLabelBgColor,
  moveAbiToSecondPosition,
} from "innovate/utils/Helper";
import { Category, CountryData, LabelsGridProps } from "innovate/utils/types";
import React from "react";

export const LabelsGrid: React.FC<LabelsGridProps> = ({ label }) => (
  <Grid templateColumns="repeat(3, 1fr)" gap={4} mb={2}>
    {["RB", "CAN", "NRB"].map((type) => (
      <GridItem key={type}>
        <Center
          flexDirection="row"
          bg={getLabelBgColor(label)}
          p={2}
          h={10}
          gap={2}
          borderRadius="md"
        >
          <Image src={type === "CAN" ? beerIcon : bottleIcon} alt="icon" />
          <Text variant="custom">{type}</Text>
        </Center>
      </GridItem>
    ))}
  </Grid>
);

export const getLabelContent = (label: string) => {
  switch (label) {
    case "Entry":
      return <Text>{"<300ml"}</Text>;
    case "Frequency":
      return (
        <>
          {/* <Text as="span"></Text> */}
          <Text as="span">CANS(300-400ml), Bottle(300-600ml)</Text>
        </>
      );
    case "Intensity":
      return (
        <>
          {/* <Text as="span"></Text> */}
          <Text as="span">CANS(&gt;400ml), Bottle(&gt;600ml)</Text>
        </>
      );
    default:
      return "";
  }
};

export const findMatchingItems = (
  key: string,
  masterKey: string,
  isSubCategory: boolean,
  data: CountryData
) => {
  const matchingItems: {
    dataKey: string;
    segment: string;
    type: string;
    item: Category;
  }[] = [];

  Object.entries(data).forEach(([dataKey, dataValue]) => {
    if (Array.isArray(dataValue)) {
      dataValue.forEach((item) => {
        if (
          item.segment === key &&
          (isSubCategory
            ? item.type !== "Default" && dataKey === masterKey
            : item.type === "Default")
        ) {
          const newItem = { ...item, dataKey };
          matchingItems.push(newItem);
        }
      });
    }
  });

  return isSubCategory ? matchingItems : moveAbiToSecondPosition(matchingItems);
};

export const getBrandLabelColor = (brandCat?: string) => {
  if (brandCat === "Mega") {
    return "red";
  }
  if (brandCat === "Tail") {
    return "#8DD262";
  }
  if (brandCat === "Expansion") {
    return "#F1D513";
  }
  if (brandCat === "Sustain") {
    return "#9ED0F5";
  }
  return "#8DD262";
};

export const createBrandLabel = (category: Category) => {
  if (category.type === "Default" && category.dataKey === "abi") {
    return <Image src={abiBrand} alt="abiBrand" w="70px" />;
  } else if (
    category.type === "Default" &&
    category.dataKey === "heineken beverages"
  ) {
    return <Image src={heineken} alt="heineken" w="70px" />;
  } else {
    return category.type;
  }
};

// export const sortByBrandCategory = (items: any[]) => {
//   const order = ["Mega", "Sustain", "Expansion", "Tail"];
//   return items.sort((a, b) => {
//     const indexA = order.indexOf(a["Brand Category"]);
//     const indexB = order.indexOf(b["Brand Category"]);
//     return indexA - indexB;
//   });
// };

export const sortByBrandCategory = (items: any[]) => {
  const order = ["Mega", "Sustain", "Expansion", "Tail"];
  return items.sort((a, b) => {
    const indexA = a["Brand Category"]
      ? order.indexOf(a["Brand Category"])
      : -1;
    const indexB = b["Brand Category"]
      ? order.indexOf(b["Brand Category"])
      : -1;

    if (indexA === -1 && indexB === -1) return 0;
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;

    return indexA - indexB;
  });
};
